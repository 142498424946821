var data = {
    'show_type': 'only_main_drugs', // all
    'apply_drug_arrange_to_next_days': 1,
    'show': false,
    'new': '',
    'day': '2022-01-01',
    'day_data': {
        'day_name': '1. siklus 1. gün',
        'day_today_difference': 0,
        'DayApplicationStatusId': 1,
        'application_list': [{
            'id': 1,
            'PatientId': 1,
            'PatientTreatmentId': 1,
            'PatientTreatmentDrugsId': 1,
            'DrugId': 2,
            'DrugName': 'Deksametazon',
            'DrugApplicationStatusId': 1,
            'DrugApplicationStatusLabel': 'Henüz işlem yapılmadı',
            'DrugTypeId': 2,
            'DrugTypeLabel': 'Ön premedikasyon',
            'UnitId': 1,
            'UnitLabel': 'mg',
            'ApplicationTypeId': 12,
            'ApplicationTypeLabel': 'intravenöz',
            'FluidId': 1,
            'FluidName': '100 cc % 0.9 sodyum klorür',
            'CreatedBy': 1,
            'CreatedByName': 'Ad Soyad',
            'application_start_date': '2022-01-01T09:00:00.000000',
            'application_end_date': '2022-01-01T12:00:00.000000',
            'drug_order': 1,
            'related_drug_id': 2,
            'drug_day': '2022-01-01',
            'dosage': 8,
            'calculated_dosage': 8,
            'planned_dosage': 8,
            'given_dosage': '',
            'frequency_first': 1,
            'frequency_second': 1,
            'drug_note': '',
            'volume': 100,
            'application_time': 10,
            'drop_minute': 10,
            'infusion_rate': 1000,
            'day': 1,
            'cycle': 1,
        }, {
            'id': 2,
            'PatientId': 1,
            'PatientTreatmentId': 1,
            'PatientTreatmentDrugsId': 1,
            'DrugId': 3,
            'DrugName': 'Granisetron',
            'DrugApplicationStatusId': 1,
            'DrugApplicationStatusLabel': 'Henüz işlem yapılmadı',
            'DrugTypeId': 5,
            'DrugTypeLabel': 'Karışım ilacı',
            'UnitId': 1,
            'UnitLabel': 'mg',
            'ApplicationTypeId': 12,
            'ApplicationTypeLabel': 'intravenöz',
            'FluidId': '',
            'FluidName': '',
            'CreatedBy': 1,
            'CreatedByName': 'Ad Soyad',
            'application_start_date': '2022-01-01T09:00:00.000000',
            'application_end_date': '2022-01-01T12:00:00.000000',
            'drug_order': 1,
            'related_drug_id': 1,
            'drug_day': '2022-01-01',
            'dosage': 3,
            'calculated_dosage': 3,
            'planned_dosage': 3,
            'given_dosage': '',
            'frequency_first': 1,
            'frequency_second': 1,
            'drug_note': '',
            'volume': '',
            'application_time': '',
            'drop_minute': '',
            'infusion_rate': '',
            'day': 1,
            'cycle': 1,
        }, {
            'id': 3,
            'PatientId': 1,
            'PatientTreatmentId': 1,
            'PatientTreatmentDrugsId': 1,
            'DrugId': 1,
            'DrugName': 'Paklitaksel',
            'DrugApplicationStatusId': 1,
            'DrugApplicationStatusLabel': 'Henüz işlem yapılmadı',
            'DrugTypeId': 1,
            'DrugTypeLabel': 'İlaç',
            'UnitId': 1,
            'UnitLabel': 'mg/m2',
            'ApplicationTypeId': 12,
            'ApplicationTypeLabel': 'intravenöz',
            'FluidId': 1,
            'FluidName': '100 cc % 0.9 sodyum klorür',
            'CreatedBy': 1,
            'CreatedByName': 'Ad Soyad',
            'application_start_date': '2022-01-01T09:00:00.000000',
            'application_end_date': '2022-01-01T12:00:00.000000',
            'drug_order': 2,
            'related_drug_id': '',
            'drug_day': '2022-01-01',
            'dosage': 80,
            'calculated_dosage': 200,
            'planned_dosage': 120,
            'given_dosage': '',
            'frequency_first': 1,
            'frequency_second': 1,
            'drug_note': 'İlacı yavaş infüzyonla uygula',
            'volume': 100,
            'application_time': 60,
            'drop_minute': 150,
            'infusion_rate': 100,
            'day': 1,
            'cycle': 1,
        }]
    },
    'offset': 0,
    'reason': []
};

export { data as example_data };