<template>
  <div style="margin: 10px;">
    <b-row>
      <b-col sm="12" lg="1"></b-col>
      <b-col sm="12" lg="8">
        <work-subject :p_showDetails="{'type': 'selected', 'subject_name': 'treatmentdrugarrangepage'}"></work-subject>
        <hr>
        <h3>Komponent Görünümü</h3>
        <hr>
        <div style="width: 100%;">
          <img src="../../../public/demo/treatmentdrugarrangepage.png" width="100%">
        </div>
        <hr>
        <h3>Komponent açılan modalde görünüm</h3>
        <h6>Butona tıklayarak açılan modalde ilaç uygulama düzenleme komponentini görüntüleyebilirsiniz</h6>
        <b-row>
          <b-col sm="12" lg="12">
            <b-button @click="f_openTreatmentDrugChangeModal('2022-01-01')" variant="primary">gün ilaç düzenleme ekranı</b-button>
          </b-col>
        </b-row>
        <hr>
        <template v-for="(code_example, code_example_index)  in d_componentExample.schema_drugs">
          <h3> {{ code_example.name }} </h3>
          <div class="code-text">{{ code_example.code }}</div>
        </template>
        <hr>
        <sql-database :p_tableShowDetails="{'type': 'selected', 'table_name': 'PatientTreatmentDrugs'}"></sql-database>
        <hr>
        <sql-database :p_tableShowDetails="{'type': 'selected', 'table_name': 'PatientTreatmentDrugsApplication'}"></sql-database>
        <hr>
      </b-col>
      <b-col sm="12" lg="3"></b-col>
    </b-row>
    <b-modal v-if="d_treatmentDrugArrange.show" v-model="d_treatmentDrugArrange.show" centered class="modal-success" @ok="d_treatmentDrugArrange.show = false" ok-variant="success" hide-footer size="lg">
      <b-row>
        <b-col cols="12">
          <img src="../../../public/demo/treatmentdrugarrangepage.png" width="100%">
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" style="text-align: right;">
          <b-button variant="danger" @click="d_treatmentDrugArrange.show = false">Kapat</b-button>
          <b-button variant="success" @click="f_applyDayDrugArrange()">İlaç Değişimlerini Uygula</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  default as WorkSubject
} from '@/components/widgets/WorkSubject';
import {
  default as SqlDatabase
} from '@/components/widgets/SqlDatabase';
import { mapGetters } from 'vuex';
export default {
  name: 'TreatmentDrugArrangePage',
  computed: mapGetters({
    // lang: 'lang'
  }),
  components: {
    WorkSubject,
    SqlDatabase,
  },
  props: {},
  data () {
    return {
      d_consoleLog: true,
      d_showTreatmentData: true,
      d_treatmentDrugArrange: {
        'show_type': 'only_main_drugs', // all
        'apply_drug_arrange_to_next_days': 1,
        'show': false,
        'new': '',
        'day': '',
        'day_data': '',
        'offset': 0,
        'reason': []
      },
      d_treatmentDrugArrangeExample: require('@/example_data/treatment_drug_arrange').example_data,
      d_treatmentList: require('@/example_data/treatment_list').example_data,
      d_patientData: require('@/example_data/patient_data').example_data,
      d_treatmentData: require('@/example_data/treatment_data').example_data,
      d_componentExample: {
        'schema_drugs': [
          { 'code': '<treatment-drug-arrange :p_treatmentData="d_treatmentData" :p_treatmentDrugArrange="d_treatmentDrugArrange" :p_day="d_treatmentDrugArrange.day" :p_patientData="d_patientData"></treatment-drug-arrange>', 'name': 'Örnek komponent kullanımı' },
        ]
      },
    };
  },
  created: function () {},
  beforeMount () {},
  mounted: function () {},
  methods: {
    f_showTreatmentData: function () {
      this.d_showTreatmentData = false;
      setTimeout(function () {
        this.d_showTreatmentData = true;
      }.bind(this), 500);
    },
    f_applyDayDrugArrange: function () {
      this.d_consoleLog ? console.log('f_applyDayDrugArrange ...') : '';
      if (this.d_treatmentDrugArrange.apply_drug_arrange_to_next_days) {
        let date_started = false;
        for (let i in this.d_treatmentData.drug_day_order) {
          let next_day = this.d_treatmentData.drug_day_order[i];
          this.d_consoleLog ? console.log('next_day : ', next_day) : '';
          if (next_day === this.d_treatmentDrugArrange.day) {
            date_started = true;
          }
          if (date_started) {
            this.d_consoleLog ? console.log('date_started : ', date_started) : '';
            for (let app_drug_index in this.d_treatmentData.drug_day_list[next_day].application_list) {
              let app_drug_data = this.d_treatmentData.drug_day_list[next_day].application_list[app_drug_index];
              for (let arranged_drug_index in this.d_treatmentDrugArrange.day_data.application_list) {
                let arranged_drug_data = this.d_treatmentDrugArrange.day_data.application_list[arranged_drug_index];
                if (app_drug_data.DrugId === arranged_drug_data.DrugId) {
                  this.d_consoleLog ? console.log('Same drug and dosage found.', app_drug_data.DrugName) : '';
                  this.d_treatmentData.drug_day_list[next_day].application_list[app_drug_index] = JSON.parse(JSON.stringify(this.d_treatmentDrugArrange.day_data.application_list[arranged_drug_index]));
                  break;
                }
              }
            }
          }
        }
      } else {
        this.d_consoleLog ? console.log('Apply arrangement to this day : ') : '';
        this.d_treatmentData.drug_day_list[this.d_treatmentDrugArrange.day] = JSON.parse(JSON.stringify(this.d_treatmentDrugArrange.day_data));
      }
      this.f_showTreatmentData();
      this.d_treatmentDrugArrange.show = false;
    },
    f_openTreatmentDrugChangeModal: function (day) {
      this.d_treatmentDrugArrange = {
        'show_type': 'only_main_drugs', // all
        'apply_drug_arrange_to_next_days': 1,
        'show': true,
        'new': '',
        'offset': 0,
        'reason': [],
        'day': day,
        'day_data': JSON.parse(JSON.stringify(this.d_treatmentData.drug_day_list[day]))
      };
    }
  },
  watch: {}
}

</script>

<style type="text/css">


</style>

